var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-wrapper" }, [
    _c(
      "div",
      { staticClass: "page-inner" },
      [
        _c("div", { staticClass: "page-title-wrapper" }, [
          _c("div", { staticClass: "container" }, [
            _c(
              "div",
              {
                staticClass: "page-title-inner",
                class: {
                  "page-title-inner--driver": _vm.$route.name === "Drivers",
                  "page-title-inner--enterprise": _vm.user.id === _vm.adminID
                }
              },
              [
                _c("div", { staticClass: "users__title page-title" }, [
                  _vm._v("Users")
                ]),
                _c(
                  "router-link",
                  {
                    staticClass: "link",
                    attrs: {
                      to: "/users/drivers/",
                      "active-class": "link--active"
                    }
                  },
                  [
                    _c("button", { staticClass: "button" }, [
                      _c("i", { staticClass: "ri-car-fill" }),
                      _c("span", [_vm._v("Drivers")])
                    ])
                  ]
                ),
                _c(
                  "router-link",
                  {
                    staticClass: "link",
                    attrs: {
                      to: "/users/customers/",
                      "active-class": "link--active"
                    }
                  },
                  [
                    _c("button", { staticClass: "button" }, [
                      _c("i", { staticClass: "ri-user-3-fill" }),
                      _c("span", [_vm._v("Customers")])
                    ])
                  ]
                ),
                _vm.user.id === _vm.adminID
                  ? _c(
                      "router-link",
                      {
                        staticClass: "link",
                        attrs: {
                          to: "/users/enterprise/",
                          "active-class": "link--active"
                        }
                      },
                      [
                        _c("button", { staticClass: "button" }, [
                          _c("i", { staticClass: "ri-building-2-fill" }),
                          _c("span", [_vm._v("Enterprise")])
                        ])
                      ]
                    )
                  : _vm._e(),
                _c(
                  "router-link",
                  {
                    staticClass: "link",
                    attrs: {
                      to: "/users/clients/",
                      "active-class": "link--active"
                    }
                  },
                  [
                    _c("button", { staticClass: "button" }, [
                      _c("i", { staticClass: "ri-user-2-fill" }),
                      _c("span", [_vm._v("Public Clients")])
                    ])
                  ]
                ),
                _vm.$route.name === "PublicCustomers"
                  ? _c("el-input", {
                      attrs: {
                        id: "searchClient",
                        "prefix-icon": "el-icon-search",
                        placeholder: "Search client",
                        size: "small"
                      },
                      model: {
                        value: _vm.searchClient,
                        callback: function($$v) {
                          _vm.searchClient = $$v
                        },
                        expression: "searchClient"
                      }
                    })
                  : _vm.$route.name === "Customers"
                  ? _c("el-input", {
                      attrs: {
                        id: "searchCustomer",
                        "prefix-icon": "el-icon-search",
                        placeholder: "Search customers",
                        size: "small"
                      },
                      model: {
                        value: _vm.searchCustomers,
                        callback: function($$v) {
                          _vm.searchCustomers = $$v
                        },
                        expression: "searchCustomers"
                      }
                    })
                  : _vm.$route.name === "EnterpriseCustomers"
                  ? _c("el-input", {
                      attrs: {
                        id: "searchEnterprise",
                        "prefix-icon": "el-icon-search",
                        placeholder: "Search enterprise customers",
                        size: "small"
                      },
                      model: {
                        value: _vm.searchEnterprise,
                        callback: function($$v) {
                          _vm.searchEnterprise = $$v
                        },
                        expression: "searchEnterprise"
                      }
                    })
                  : _c("div"),
                _c("div", { staticClass: "users__button-wrapper" }, [
                  _vm.$route.name !== "PublicCustomers"
                    ? _c(
                        "button",
                        {
                          staticClass: "button button--fill",
                          on: { click: _vm.showAddPopup }
                        },
                        [
                          _c("i", { staticClass: "ri-add-circle-fill" }),
                          _vm.$route.name === "Drivers"
                            ? _c("span", [_vm._v("Add New Driver")])
                            : _vm.$route.name === "Customers" ||
                              _vm.$route.name === "EnterpriseCustomers"
                            ? _c("span", [_vm._v(" Add New Customer ")])
                            : _vm._e()
                        ]
                      )
                    : _vm._e(),
                  _vm.$route.name === "Customers" ||
                  _vm.$route.name === "EnterpriseCustomers"
                    ? _c(
                        "button",
                        {
                          staticClass:
                            "icon-button icon-button--mobile icon-button--blue button-fly",
                          on: { click: _vm.showAddPopup }
                        },
                        [_c("i", { staticClass: "ri-add-circle-fill" })]
                      )
                    : _vm._e(),
                  _vm.$route.name === "Drivers"
                    ? _c(
                        "button",
                        {
                          staticClass:
                            "icon-button icon-button--mobile icon-button--blue button--driver",
                          on: { click: _vm.showAddPopup }
                        },
                        [
                          _c("i", { staticClass: "ri-add-circle-fill" }),
                          _c("span", [_vm._v("Add New Driver")])
                        ]
                      )
                    : _vm._e()
                ])
              ],
              1
            )
          ])
        ]),
        _c("router-view")
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }