<template>
  <div class="page-wrapper">
    <div class="page-inner">
      <div class="page-title-wrapper">
        <div class="container">
          <div
            class="page-title-inner"
            :class="{
              'page-title-inner--driver': $route.name === 'Drivers',
              'page-title-inner--enterprise': user.id === adminID
            }"
          >
            <div class="users__title page-title">Users</div>

            <router-link to="/users/drivers/" class="link" active-class="link--active">
              <button class="button">
                <i class="ri-car-fill"></i>
                <span>Drivers</span>
              </button>
            </router-link>
            <router-link to="/users/customers/" class="link" active-class="link--active">
              <button class="button">
                <i class="ri-user-3-fill"></i>
                <span>Customers</span>
              </button>
            </router-link>
            <router-link v-if="user.id === adminID" to="/users/enterprise/" class="link" active-class="link--active">
              <button class="button">
                <i class="ri-building-2-fill"></i>
                <span>Enterprise</span>
              </button>
            </router-link>
            <router-link to="/users/clients/" class="link" active-class="link--active">
              <button class="button">
                <i class="ri-user-2-fill"></i>
                <span>Public Clients</span>
              </button>
            </router-link>
            <el-input
              v-if="$route.name === 'PublicCustomers'"
              id="searchClient"
              v-model="searchClient"
              prefix-icon="el-icon-search"
              placeholder="Search client"
              size="small"
            />
            <el-input
              v-else-if="$route.name === 'Customers'"
              id="searchCustomer"
              v-model="searchCustomers"
              prefix-icon="el-icon-search"
              placeholder="Search customers"
              size="small"
            />
            <el-input
              v-else-if="$route.name === 'EnterpriseCustomers'"
              id="searchEnterprise"
              v-model="searchEnterprise"
              prefix-icon="el-icon-search"
              placeholder="Search enterprise customers"
              size="small"
            />
            <div v-else></div>
            <div class="users__button-wrapper">
              <button v-if="$route.name !== 'PublicCustomers'" class="button button--fill" @click="showAddPopup">
                <i class="ri-add-circle-fill"></i>
                <span v-if="$route.name === 'Drivers'">Add New Driver</span>
                <span v-else-if="$route.name === 'Customers' || $route.name === 'EnterpriseCustomers'">
                  Add New Customer
                </span>
              </button>
              <button
                v-if="$route.name === 'Customers' || $route.name === 'EnterpriseCustomers'"
                class="icon-button icon-button--mobile icon-button--blue button-fly"
                @click="showAddPopup"
              >
                <i class="ri-add-circle-fill"></i>
              </button>
              <button
                v-if="$route.name === 'Drivers'"
                class="icon-button icon-button--mobile icon-button--blue button--driver"
                @click="showAddPopup"
              >
                <i class="ri-add-circle-fill"></i>
                <span>Add New Driver</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import {mapActions, mapMutations, mapGetters} from 'vuex'

export default {
  name: 'Users',
  data() {
    return {
      searchClient: '',
      searchCustomers: '',
      searchEnterprise: '',
      adminID: +process.env.VUE_APP_ADMIN_ID
    }
  },
  computed: {
    ...mapGetters({
      user: 'decryptToken'
    })
  },
  watch: {
    searchClient(client) {
      this.getClients(this, client)
    },
    searchCustomers(customer) {
      this.getCustomers(this, customer)
    },
    searchEnterprise(customer) {
      this.getEnterprise(this, customer)
    }
  },
  mounted() {
    this._keyListener = function(e) {
      if (e.key === 'f' && (e.ctrlKey || e.metaKey)) {
        e.preventDefault()
        this.focus()
      }
    }
    document.addEventListener('keydown', this._keyListener.bind(this))
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this._keyListener)
  },
  methods: {
    ...mapActions({
      fetchCustomers: 'customers/fetch',
      fetchEnterprise: 'customers/enterprise/fetch',
      fetchClients: 'publicCustomers/fetch'
    }),
    ...mapMutations({
      resetCustomers: 'customers/reset',
      resetEnterprise: 'customers/enterprise/reset',
      resetClients: 'publicCustomers/reset'
    }),
    showAddPopup() {
      this.$root.$emit('ShowSidebar', {
        sidebarName: this.$route.name === 'Drivers' ? 'AddDriver' : 'AddCustomer'
      })
    },
    getClients: _.debounce(async function() {
      this.resetClients()
      await this.fetchClients({'filters[businessName]': this.searchClient})
    }, 500),
    getCustomers: _.debounce(async function() {
      this.resetCustomers()
      await this.fetchCustomers({'filters[businessName]': this.searchCustomers})
    }, 500),
    getEnterprise: _.debounce(async function() {
      this.resetEnterprise()
      await this.fetchEnterprise({'filters[businessName]': this.searchEnterprise})
    }, 500),
    focus() {
      const searchCustomer = document.querySelector('#searchCustomer')
      const searchEnterprise = document.querySelector('#searchEnterprise')
      const searchClient = document.querySelector('#searchClient')
      if (searchCustomer) {
        searchCustomer.focus()
      } else if (searchClient) {
        searchClient.focus()
      } else if (searchEnterprise) {
        searchEnterprise.focus()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
